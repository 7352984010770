<template>
  <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
    <!-- THIS MONTH -->
    <div class="card bg-white border border-solid border-grey-light rounded-lg sm:p-7 p-5">
      <div v-if="monthDataLoading" class="card-loader"></div>
      <template v-else>
        <div class="flex items-center justify-between">
          <p :class="`text-sm opacity-75 ${$i18n.locale === 'en' ? 'tracking-widest' : ''}`">{{ $t('THIS MONTH') }}</p>
          <vx-tooltip position="top" color="dark" :text="`${$t('Total Cod collected from the delivered orders including the shipping fees')}`">
            <span class="material-symbols-outlined text-grey text-xl cursor-pointer">info</span>
          </vx-tooltip>
        </div>
        <div class="mt-3 flex flex-col justify-between md:gap-3 gap-5" style="height: calc(100% - 30px);">
          <p class="leading-tight text-lg font-medium text-black">{{ $t('Total Revenue') }}</p>
          <div v-if="monthData.total_revenue > 0" class="flex items-center justify-between gap-1 flex-wrap">
            <p class="sm:text-3xl text-2xl font-medium text-black flex gap-1">
              <span>{{ monthData.total_revenue.toLocaleString('en') }}</span>
              <span>{{ $t('EGP') }}</span>
            </p>
            <p class="flex gap-1 items-center py-2 px-4 rounded-full text-xl ml-auto" :class="monthData.total_revenue_percentage_change > 0 ? 'high-percentage' : 'low-percentage'">
              <span class="material-symbols-rounded text-lg">{{monthData.total_revenue_percentage_change > 0 ? 'north' : 'south'}}</span>
              <span>{{ Math.abs(monthData.total_revenue_percentage_change) + '%' }}</span>
            </p>
          </div>
          <p v-else class="text-sm">{{$t('No data available yet')}}</p>
        </div>
      </template>
    </div>

    <!-- THIS MONTH -->
    <div class="card bg-white border border-solid border-grey-light rounded-lg sm:p-7 p-5">
      <div v-if="shippingFeesLoading" class="card-loader"></div>
      <template v-else>
        <div class="flex items-center justify-between">
          <p :class="`text-sm opacity-75 ${$i18n.locale === 'en' ? 'tracking-widest' : ''}`">{{ $t('THIS MONTH') }}</p>
          <vx-tooltip position="top" color="dark" :text="`${$t('The average cost per shipment based on your total orders volume')}`">
            <span class="material-symbols-outlined text-grey text-xl cursor-pointer">info</span>
          </vx-tooltip>
        </div>
        <div class="mt-3 flex flex-col justify-between md:gap-3 gap-5" style="height: calc(100% - 30px);">
          <p class="leading-tight text-lg font-medium text-black">{{ $t('Avg. Shipping Price') }}</p>
          <p class="sm:text-3xl text-2xl font-medium text-black flex gap-1">
            <span>{{ shippingFees.average_shipping_fees }}</span>
            <span>{{ $t('EGP') }}</span>
          </p>
        </div>
      </template>
    </div>

    <!-- LAST 30 DAYS -->
    <div class="card bg-white border border-solid border-grey-light rounded-lg sm:p-7 p-5">
      <div v-if="successRateLoading" class="card-loader"></div>
      <template v-else>
        <div class="flex items-center justify-between">
          <p :class="`text-sm opacity-75 ${$i18n.locale === 'en' ? 'tracking-widest' : ''}`">{{ $t('LAST 30 DAYS') }}</p>
          <vx-tooltip position="top" color="dark" :text="`${$t('The percentage of total delivered orders out of all picked-up orders, excluding orders that are still in progress or refused')}`">
            <span class="material-symbols-outlined text-grey text-xl cursor-pointer">info</span>
          </vx-tooltip>
        </div>
        <div class="mt-3 flex flex-col justify-between md:gap-3 gap-5" style="height: calc(100% - 30px);">
          <p class="leading-tight text-lg font-medium text-black">{{ $t('Success Rate') }}</p>
          <div v-if="successRateData.success_rate > 0" class="flex items-center justify-between gap-1 flex-wrap">
            <p class="sm:text-3xl text-2xl font-medium text-black">{{ successRateData.success_rate + '%'}}</p>
            <p class="flex gap-1 items-center py-2 px-4 rounded-full text-xl ml-auto" :class="successRateData.success_rate_percentage_chage > 0 ? 'high-percentage' : 'low-percentage'">
              <span class="material-symbols-rounded text-lg">{{successRateData.success_rate_percentage_chage > 0 ? 'north' : 'south'}}</span>
              <span>{{ Math.abs(successRateData.success_rate_percentage_change) + '%' }}</span>
            </p>
          </div>
          <p v-else class="text-sm">{{$t('No data available yet')}}</p>
        </div>
      </template>
    </div>

    <!-- CUSTOMERS REVIEWS -->
    <div class="card bg-white border border-solid border-grey-light rounded-lg sm:p-6 p-4">
      <div v-if="customerReviewsLoading" class="card-loader"></div>
      <template v-else>
        <div class="flex items-center justify-between px-1">
          <p :class="`text-sm opacity-75 ${$i18n.locale === 'en' ? 'tracking-widest' : ''}`">{{ customerReviews.length > 0 ? $t('CUSTOMERS REVIEWS') : $t('MYBLU APP') }}</p>
          <div class="flex items-center gap-1">
            <div v-if="customerReviews.length > 0" class="relative">
              <span @click="copied('https://onelink.to/myblu2')" class="material-symbols-rounded fill flex text-grey text-xl cursor-pointer">share</span>
              <span v-if="linkCopied" class="absolute text-xs whitespace-nowrap" style="top: -14px; left: calc(50% - 18px);">{{ $t('Copied') }}</span>
            </div>
            <vx-tooltip position="top" color="dark" :text="`${$t('Your customers\' reviews on our delivery services from myBlu app')}`">
              <span class="flex material-symbols-outlined text-grey text-xl cursor-pointer">info</span>
            </vx-tooltip>
          </div>
        </div>
        <div v-if="customerReviews.length === 0" class="mt-3 flex flex-col justify-between md:gap-3 gap-5 px-1" style="height: calc(100% - 30px);">
          <p class="text-lg font-medium text-black leading-tight">{{ $t('Let your customers pay online and review their delivery—all in the myBlu app!') }}</p>
          <div class="flex items-center justify-between">
            <div class="relative flex items-center gap-1 border-0 border-b border-solid border-primary">
              <p @click="copied('https://onelink.to/myblu2')" class="text-sm text-primary cursor-pointer leading-none">{{ $t('Copy Download Link') }}</p>
              <span v-if="linkCopied" class="absolute text-xs" style="top: -16px; left: calc(50% - 18px);">{{ $t('Copied') }}</span>
            </div>
            <img src="@/assets/images/pages/year-in-review/myblu-logo.svg" width="78" style="filter: drop-shadow(0px 10000px 0 #1c5bfe); transform: translateY(-10000px);" alt="myBlu">
          </div>
        </div>
        <div v-else class="overflow-hidden" style="height: calc(100% - 24.5px);">
          <div class="flex transition-transform duration-300 ease-in-out" :style="`height: calc(100% - 21px); ${$i18n.locale === 'en' ? `transform: translateX(-${currentReview * 100}%)` : `transform: translateX(${currentReview * 100}%)`}`">
            <div v-for="(review, reviewIndex) in customerReviews" :key="reviewIndex" class="w-full h-full flex flex-col justify-between md:gap-3 gap-5 flex-shrink-0 px-1">
              <p class="mt-3 text-lg font-medium text-black leading-tight">{{ review.text }}</p>
              <div class="flex items-center justify-between">
                <div>
                  <p v-for="(star, index) in 5" :key="index" class="inline-flex" style="margin: 0px -3px;">
                    <span class="material-symbols-rounded fill flex text-2xl mr-2" :style="`color: ${index < review.stars ? '#FFCC49' : '#D9D9D9'};`">star</span>
                  </p>
                  <p class="font-semibold text-black leading-tight">{{ review.customer_name }}</p>
                </div>
                <img src="@/assets/images/pages/year-in-review/myblu-logo.svg" width="78" style="filter: drop-shadow(0px 10000px 0 #1c5bfe); transform: translateY(-10000px);" alt="myBlu">
              </div>
            </div>
          </div>
          <div class="mt-4 flex gap-1 px-1">
            <p v-for="(dot, dotIndex) in customerReviews" :key="dotIndex" @click="currentReview = dotIndex" class="h-2 w-2 rounded-full cursor-pointer" :style="`${dotIndex === currentReview ? 'background: #779DFE;' : 'background: #DDDDDD;'}`"></p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'

export default {
  data () {
    return {
      shippingFees: {
        average_shipping_fees: 0.00
      },
      monthData: {
        total_revenue: 0,
        total_revenue_percentage_change: 0
      },
      successRateData: {
        success_rate: 0,
        success_rate_percentage_chage: 0
      },
      customerReviews: [],
      currentReview: 0,
      shippingFeesLoading: false,
      monthDataLoading: false,
      successRateLoading: false,
      customerReviewsLoading: false,
      linkCopied: false
    }
  },
  methods: {
    loadAverageShippingFees () {
      this.shippingFeesLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/average-shipping-fees/', 'get', null, true,
        (response) => {
          this.shippingFees = response.data
          this.shippingFeesLoading = false
        }, (error) => {
          this.shippingFeesLoading = false
          common.notifyResponseError(this, error)
        }
      )
    },
    loadMonthData () {
      this.monthDataLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/this-month/', 'get', null, true,
        (response) => {
          this.monthData = response.data
          this.monthDataLoading = false
        }, (error) => {
          this.monthDataLoading = false
          common.notifyResponseError(this, error)
        }
      )
    },
    loadData () {
      this.successRateLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/last-30-days/', 'get', null, true,
        (response) => {
          this.successRateData = response.data
          this.successRateLoading = false
        }, (error) => {
          this.successRateLoading = false
          common.notifyResponseError(this, error)
        }
      )
    },
    loadCustomerReviews () {
      this.customerReviewsLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/customer-reviews/', 'get', null, true,
        (response) => {
          this.customerReviews = response.data.filter(item => item.stars === 5).slice(0, 5)
          this.customerReviewsLoading = false
        }, (error) => {
          this.customerReviewsLoading = false
          common.notifyResponseError(this, error)
        }
      )
    },
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
        this.linkCopied = true
        setTimeout(() => {
          this.linkCopied = false
        }, 750)
      } catch ($e) {
        this.$vs.notify({
          color: 'dark',
          title: i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    }
  },
  created () {
    this.loadAverageShippingFees()
    this.loadMonthData()
    this.loadData()
    this.loadCustomerReviews()
    setInterval(() => {
      this.currentReview = this.currentReview < this.customerReviews.length - 1 ? this.currentReview + 1 : 0
    }, 5000)
  }
}
</script>

<style lang="scss">
.card {
  min-height: 185px;
}
@media (max-width: 768px) {
  .card {
    min-height: 150px;
  }
}
.low-percentage {
  color: #EA5455;
  background: #EA545540;
}
.high-percentage {
  color: #28C76F;
  background: #28C76F40;
}
</style>